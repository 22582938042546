import React, { useState, useEffect } from 'react';
import {APIProvider, Map, InfoWindow, MapControl, ControlPosition} from '@vis.gl/react-google-maps';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Checkbox, FormControlLabel } from '@mui/material';
import ResponsiveAppBar from '../../components/appbar/Appbar';
import { JumpToLocationButton } from './components/JumpToLocationButton';
import {ClusteredMarkers} from './marker_clustering/clustered-markers';
import {loadNailSalondata, NailSalonsGeo} from './nail_salons';
import {Feature, Point} from 'geojson';
import {InfoWindowContent} from './marker_clustering/info-window-content';
import '../../App.css';
import './Mapstyle.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';


const StyledDialogContent = styled(DialogContent)({
  background: '#fff8f5',
  textAlign: 'right',
  direction: 'rtl',
  minWidth: '100px',
  maxWidth: '375px',
  // maxHeight: '400px', // Set maximum height
  overflowY: 'auto', // Enable vertical scrolling
  overflowX: 'hidden', // Disable horizontal scrolling
  '& .MuiDialogContentText-root': {
    textAlign: 'right',
    whiteSpace: 'pre-wrap', // Better text wrapping
    '& ul': {
      paddingRight: '5px',
      paddingLeft: 0,
      marginTop: '10px',
      marginBottom: '10px',
      listStyle: 'inside', // Keep bullets within content
    },
    '& strong': {
      fontWeight: 700,
    },
  },
});

const StyledDialogTitle = styled(DialogTitle)({
  textAlign: 'right',
  direction: 'rtl',
  fontWeight: 700,
});

const StyledFormControlLabel = styled(FormControlLabel)({
  direction: 'rtl',
  marginLeft: 0,
  marginRight: '-11px',
});

const MapPage: React.FC = () => {
  const [loading, setLoading] = useState(true);  
  const [locationLoaded, setLocationLoaded] = useState(false);

  const [userLocation, setUserLocation] = useState<google.maps.LatLngLiteral | null>({lat: 31.777380919034055, lng: 35.23548216717965});
  const [AllShops, setAllShops] = useState<NailSalonsGeo | null>(null);
  const [numClusters, setNumClusters] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [infowindowData, setInfowindowData] = useState<{
    anchor: google.maps.marker.AdvancedMarkerElement;
    features: Feature<Point>[];
  } | null>(null);

  const api_key = `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

  useEffect(() => {
    window.scrollTo(0, 0);

    const shouldShow = localStorage.getItem('hideWelcomeDialog') !== 'true';
    if (shouldShow) {
      setOpenDialog(true);
    }

    void loadNailSalondata().then(data => setAllShops(data));
    if (navigator.geolocation) {
      try {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            setUserLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
            setLocationLoaded(true);
          },
          (error) => {
            console.log('Geolocation error:', error);
            setLocationLoaded(true);
          },
        { enableHighAccuracy: true }
        );
      } catch (err) {
        console.log(err);
      }
    }
    else {
      setLocationLoaded(true);
    }

  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [locationLoaded]);

  const handleCloseDialog = () => {
    if (dontShowAgain) {
      localStorage.setItem('hideWelcomeDialog', 'true');
    }
    setOpenDialog(false);
  };

  const handleInfoWindowClose = () => setInfowindowData(null);

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}>
        <CircularProgress />
      </div>
    );
  }
  else {
    return (
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        height: '100vh',
        width: '100vw',
        overflow: 'hidden'
      }}>
        <APIProvider apiKey={api_key} version={'beta'}>
          {/* <ResponsiveAppBar /> */}
          
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="welcome-dialog-title"
          >
            <StyledDialogContent>
            <StyledDialogTitle id="welcome-dialog-title">
              ברוכים הבאים לילק!
            </StyledDialogTitle>
            
            <DialogContentText component="div">
              האתר שלנו נועד להפוך את החיפוש אחר שירותי לק ג'ל לקל, מהיר ונוח.
              <ul>
                <li><strong>מצאו שירותי לק ג'ל לפי מיקום</strong>: בחרו לק ג'לית קרובות אליכם.</li>
                <li><strong>יצירת קשר בקלות</strong>: התחברו ישירות עם מבצעות הלק ג'ל שיתאימו לצרכים שלכם.</li>
                <li><strong>קביעת תורים מידית</strong>: קבעו תור במהירות ובנוחות, ללא המתנה.</li>
              </ul>
              כל מה שנדרש הוא להירשם עם <strong>מספר הטלפון</strong> שלכם לצורך זיהוי ואימות, והעולם של טיפוח הציפורניים מחכה לכם בלחיצת כפתור! 💅
              <br /><br />
              <strong>התחילו עוד היום!</strong>
            </DialogContentText>
          <StyledFormControlLabel
            control={
            <Checkbox
                checked={dontShowAgain}
                onChange={(e) => setDontShowAgain(e.target.checked)}
              />
              }
              label="אל תציג הודעה זו שוב"
            />
            <DialogActions>
              <Button 
              variant="contained"
              sx={{
              flexGrow: 6,
              // maxWidth: 200,
              // paddingY: 2,
              textAlign: 'center',
              background: 'linear-gradient(90deg, #b62a73 1%, #ffcdb3 100%)',
              }}
              onClick={handleCloseDialog}>הבנתי</Button>
            </DialogActions>
            </StyledDialogContent>
          </Dialog>
          <div style={{ 
            flex: 1,
            position: 'relative',
            overflow: 'hidden',
          }}>
            <Map
              mapId={`${process.env.REACT_APP_GOOGLE_MAP_ID}`}
              defaultCenter={userLocation} // Changed from defaultCenter to center
              defaultZoom={15}
              gestureHandling={'greedy'}
              disableDefaultUI
              clickableIcons={false}
              className={'custom-marker-clustering-map'}
              style={{
                width: '100%',
                height: '100%'
              }}
              onClick={handleInfoWindowClose}
            >
              {AllShops && (
                <ClusteredMarkers
                  geojson={AllShops}
                  setNumClusters={setNumClusters}
                  setInfowindowData={setInfowindowData}
                />
              )}

              {infowindowData && (
                <InfoWindow
                headerDisabled
                onClose={handleInfoWindowClose}
                anchor={infowindowData.anchor}
                style={{
                  width: 'auto',
                  height: 'auto',
                  padding: 0,
                }}
              >
                <InfoWindowContent features={infowindowData.features} />
              </InfoWindow>
              )}
              
              <MapControl position={ControlPosition.TOP_RIGHT}>
                <JumpToLocationButton />
              </MapControl>
            </Map>
          </div>
        </APIProvider>
      </div>
    );
  };
}

export default MapPage;