import React, { useState } from 'react';
import { useMap } from '@vis.gl/react-google-maps';
import { MyLocationSvg } from './my-location';

export const JumpToLocationButton = () => {
    const map = useMap();
    const [isLocating, setIsLocating] = useState(false);

    const handleClick = () => {
        setIsLocating(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    map?.panTo({ lat: latitude, lng: longitude });
                    map?.setZoom(15);
                    setTimeout(() => setIsLocating(false), 500);
                },
                (error) => {
                    console.error('Error getting location:', error);
                    setIsLocating(false);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }
            );
        } else {
            setIsLocating(false);
        }
    };
    return (
        <div style={{padding: '10px'}}>
        <button 
            onClick={handleClick} 
            disabled={isLocating}
            style={{
            padding: '10px',
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '4px',
            cursor: 'pointer',
            }}
        >
            <MyLocationSvg />
        </button>
        </div>
    );
}